.datasource-tabs .datasource-tab-item[data-v-219b2108] {
  padding-top: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.datasource-tabs .datasource-tab-item .add-btn-wrapper[data-v-219b2108] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    cursor: pointer;
}
.datasource-tabs .datasource-tab-item .add-btn-wrapper i[data-v-219b2108] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
.datasource-tabs .datasource-tab-item .condition-header[data-v-219b2108] {
    padding: 10px;
    background: var(--mainThemeColor);
    color: #fff;
}
.datasource-tabs .datasource-tab-item .condition-wrapper[data-v-219b2108] {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-top: 10px;
}
.datasource-tabs .datasource-tab-item .condition-wrapper .condition-btns[data-v-219b2108] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: var(--rootFontSize3);
}
.datasource-tabs .datasource-tab-item .condition-wrapper .el-form-item[data-v-219b2108] {
      width: 80%;
}
.datasource-tabs .datasource-tab-item .condition-wrapper .el-form-item .el-checkbox[data-v-219b2108] {
        line-height: 36px;
}
.datasource-tabs .datasource-tab-item .condition-wrapper[data-v-219b2108] .vue-treeselect__single-value {
      line-height: inherit;
}
.datasource-tabs .datasource-tab-item[data-v-219b2108] .datasource-option-config {
    width: 100%;
    padding: 0px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.datasource-tabs .datasource-tab-item[data-v-219b2108] .datasource-option-config .select-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border: 1px dashed #fff;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.datasource-tabs .datasource-tab-item[data-v-219b2108] .datasource-option-config .select-item .close-btn {
        cursor: pointer;
        color: #f56c6c;
}
.datasource-tabs .datasource-tab-item[data-v-219b2108] .datasource-option-config .select-item .el-input + .el-input {
        margin-left: 4px;
}
.datasource-tabs .datasource-tab-item[data-v-219b2108] .datasource-option-config .select-item .sortable-chosen {
        border: 1px dashed #409eff;
}
.datasource-tabs .datasource-tab-item[data-v-219b2108] .datasource-option-config .select-item .select-line-icon {
        line-height: 32px;
        font-size: 22px;
        padding: 0 4px;
        color: #777;
}
.datasource-tabs[data-v-219b2108] .el-form-item {
  margin-bottom: 18px !important;
}
.datasource-tabs[data-v-219b2108] .el-form-item__label {
  padding-right: 0px;
}
