.datasource-type[data-v-6c17d1c4] {
  width: 100%;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 4px 0px !important;
  border: 1px dotted #ccc;
  border-radius: 2px;
}
[data-v-6c17d1c4] .datasource-dialog .el-dialog__body {
  padding: 14px;
  min-height: 420px;
}
[data-v-6c17d1c4] .datasource-dialog .el-dialog__body .current-user-dept {
    margin-top: 10px;
    padding: 10px;
    border: 1px dotted #ccc;
}
[data-v-6c17d1c4] .datasource-dialog .el-dialog__body .current-param-type {
    margin-top: 10px;
    padding: 10px;
    border: 1px dotted #ccc;
}
[data-v-6c17d1c4] .datasource-dialog .el-dialog__body .datasource-tabs {
    margin-top: 14px;
    -webkit-box-shadow: none;
            box-shadow: none;
}
[data-v-6c17d1c4] .el-form-item__content {
  line-height: 16px;
}
