.datadource-formula-container .formula-result[data-v-707a5206] {
  width: 100%;
}
.formula-dialog[data-v-707a5206] .el-dialog__header {
  border-bottom: 1px solid #dcdfe6;
}
.formula-dialog[data-v-707a5206] .el-dialog__body {
  padding: 15px !important;
}
.formula-dialog[data-v-707a5206] .el-dialog__footer {
  border-top: 1px solid #dcdfe6;
  padding: 10px;
  text-align: center;
}
.formula-dialog .formula-content[data-v-707a5206] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.formula-dialog .formula-content .formula-factor[data-v-707a5206] {
    width: 300px;
    height: 500px;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs[data-v-707a5206] {
      height: 500px;
      -webkit-box-shadow: none;
              box-shadow: none;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-functions[data-v-707a5206] {
        margin: 10px 0px 0px 0px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        height: 360px;
        overflow-y: auto;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-functions[data-v-707a5206] .vxe-table--body {
          width: 100% !important;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-functions[data-v-707a5206] .formula-factor-function-item {
          cursor: pointer;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-fields[data-v-707a5206] {
        -webkit-padding-start: 0px;
                padding-inline-start: 0px;
        margin: 10px 0px 0px 0px;
        -webkit-box-sizing: 0px;
                box-sizing: 0px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        height: 370px;
        overflow-y: auto;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-fields .formula-factor-field-item[data-v-707a5206] {
          list-style: none;
          border-bottom: 1px solid #dcdfe6;
          padding: 0px 10px;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-fields .formula-factor-field-item p[data-v-707a5206] {
            margin: 0px;
            line-height: 24px;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-fields .formula-factor-field-item p[data-v-707a5206]:first-of-type {
              margin-top: 10px;
}
.formula-dialog .formula-content .formula-factor .formula-factor-tabs .formula-factor-tab-item .formula-factor-fields .formula-factor-field-item p[data-v-707a5206]:last-of-type {
              margin-bottom: 4px;
}
.formula-dialog .formula-content .formula-structure[data-v-707a5206] {
    width: calc(100% - 320px);
    height: 500px;
}
.formula-dialog .formula-content .formula-structure .formula-structure-remark[data-v-707a5206] {
      display: block;
      width: 100%;
      height: 92px;
      padding: 5px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
}
.formula-dialog .formula-content .formula-structure .formula-structure-remark p[data-v-707a5206] {
        font-size: var(--rootFontSize);
        color: red;
}
.formula-dialog .formula-content .formula-structure .formula-structure-content[data-v-707a5206] {
      margin-top: 10px;
      width: 100%;
      height: 390px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.formula-dialog .formula-content .formula-structure .formula-structure-empty[data-v-707a5206] {
      width: 100%;
      height: 470px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 32px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
}
