.trigger-group-wrapper[data-v-138b4b6b] {
  position: relative;
  width: 100%;
  min-height: 300px;
  margin-top: 20px;
}
.trigger-group-wrapper .add-btn-wrapper[data-v-138b4b6b] {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px dotted #ccc;
    cursor: pointer;
}
.trigger-group-wrapper .add-btn-wrapper i[data-v-138b4b6b] {
      font-size: var(--rootFontSize3);
      color: #409eff;
      line-height: inherit;
}
.trigger-group-wrapper .trigger-group-item[data-v-138b4b6b] {
    border: 1px solid #ccc;
}
.trigger-group-wrapper .trigger-group-item .trigger-group-item-top[data-v-138b4b6b] {
      width: 100%;
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background: var(--mainThemeColor);
      color: #fff;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.trigger-group-wrapper .trigger-group-item .trigger-group-item-top .trigger-group-item-title[data-v-138b4b6b] {
        width: 100px;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        font-size: var(--rootFontSize);
}
.trigger-group-wrapper .trigger-group-item .trigger-group-item-top .trigger-group-item-icon[data-v-138b4b6b] {
        width: 80px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        cursor: pointer;
}
.trigger-group-wrapper .trigger-group-item .trigger-group-item-top .trigger-group-item-icon i[data-v-138b4b6b] {
          font-size: var(--rootFontSize3);
          color: #fff;
          line-height: inherit;
          margin-right: 10px;
}
.trigger-group-wrapper .trigger-group-item .cus-divider[data-v-138b4b6b] {
      width: calc(100% - 20px) !important;
      margin: 24px 10px;
}
.trigger-group-wrapper .trigger-group-item .cus-divider .el-divider__text[data-v-138b4b6b] {
        color: #606266;
        font-weight: 500;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper[data-v-138b4b6b] {
      width: calc(100% - 20px);
      margin: 0px 10px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group[data-v-138b4b6b] {
        border: 1px solid #ccc;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-top[data-v-138b4b6b] {
          width: 100%;
          height: 40px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          background: #f5f7fa;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-top .trigger-condition-group-title[data-v-138b4b6b] {
            width: 200px;
            height: 40px;
            margin-left: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-top .trigger-condition-group-title span[data-v-138b4b6b] {
              width: 80px;
              text-align: left;
              font-size: var(--rootFontSize);
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-top .trigger-condition-group-title .el-select[data-v-138b4b6b] {
              width: 80px;
              font-size: 14px !important;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-top .trigger-condition-group-icon[data-v-138b4b6b] {
            width: 80px;
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
                -ms-flex-pack: end;
                    justify-content: flex-end;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            cursor: pointer;
            margin-right: 10px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-top .trigger-condition-group-icon i[data-v-138b4b6b] {
              font-size: var(--rootFontSize3);
              color: #fff;
              line-height: inherit;
              margin-right: 10px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content[data-v-138b4b6b] {
          width: 100%;
          padding: 10px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin-top: 10px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b]:first-of-type {
              margin-top: 0px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item {
              margin-bottom: 0px !important;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .el-date-editor {
                width: 100%;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect {
                line-height: 30px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__placeholder {
                  line-height: 30px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control {
                  height: 30px !important;
                  line-height: 30px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__single-value {
                    line-height: 30px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value {
                    height: 30px;
                    line-height: 30px;
                    margin-bottom: 0px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container {
                      height: 26px;
                      padding-top: 2px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container .vue-treeselect__multi-value-item {
                        line-height: 20px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip {
                      height: 26px;
                      padding-top: 2px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip .vue-treeselect__limit-tip-text {
                        line-height: 20px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__input-container {
                      height: 20px;
                      line-height: 20px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item .el-select {
                width: 100%;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:first-of-type {
                width: 80px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:nth-of-type(2) {
                width: 160px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:nth-of-type(3) {
                width: 160px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:nth-of-type(4) {
                width: 160px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:nth-of-type(5) {
                width: 160px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:nth-of-type(6) {
                width: 200px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b] .condtion-form-item:last-of-type {
                width: 80px;
                text-align: right;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .trigger-condition-item .condtion-form[data-v-138b4b6b]:last-of-type {
              margin-bottom: 10px;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .add-btn-wrapper[data-v-138b4b6b] {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px dotted #ccc;
            cursor: pointer;
}
.trigger-group-wrapper .trigger-group-item .trigger-condition-wrapper .trigger-condition-group .trigger-condition-group-content .add-btn-wrapper i[data-v-138b4b6b] {
              font-size: var(--rootFontSize3);
              color: #409eff;
              line-height: inherit;
}
.trigger-group-wrapper .trigger-group-item .trigger-action-wrapper[data-v-138b4b6b] {
      padding: 0px 10px 10px 10px;
}
.trigger-group-wrapper[data-v-138b4b6b]:first-of-type {
    margin-top: 0px;
}
