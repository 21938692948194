.trigger-form[data-v-8236feb8] {
  min-height: 400px;
}
.trigger-form .trigger-form-item .el-select[data-v-8236feb8] {
    width: 100%;
}
.trigger-form .cus-divider .el-divider__text[data-v-8236feb8] {
    color: #606266;
    font-weight: 500;
}
.trigger-form .affect-compontent-tabs[data-v-8236feb8] {
    -webkit-box-shadow: none;
            box-shadow: none;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .add-btn-wrapper[data-v-8236feb8] {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px dotted #ccc;
      cursor: pointer;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .add-btn-wrapper i[data-v-8236feb8] {
        font-size: var(--rootFontSize3);
        color: #409eff;
        line-height: inherit;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .el-icon-setting[data-v-8236feb8] {
      font-size: var(--rootFontSize3);
      line-height: 40px;
      cursor: pointer;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns[data-v-8236feb8] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: 10px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns div[data-v-8236feb8] {
        text-align: center;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns div[data-v-8236feb8]:first-of-type {
          width: calc(33% - 90px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns div[data-v-8236feb8]:nth-of-type(2) {
          width: calc(33% - 90px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns div[data-v-8236feb8]:nth-of-type(3) {
          width: calc(33% - 90px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns div[data-v-8236feb8]:nth-of-type(4) {
          width: 120px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-columns div[data-v-8236feb8]:last-of-type {
          width: 120px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: 10px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item {
        margin-bottom: 0px !important;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content {
          text-align: center;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .el-date-editor {
            width: 100%;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .el-select {
            width: 100%;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-radio {
            margin-right: 10px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .formula-compontent {
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -ms-flex-pack: distribute;
                justify-content: space-around;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .formula-compontent .formula-value {
              width: calc(100% - 70px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .formula-compontent .formula-order {
              width: 60px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect {
            line-height: 30px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__placeholder {
              line-height: 30px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control {
              height: 30px !important;
              line-height: 30px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__single-value {
                line-height: 30px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value {
                height: 30px;
                line-height: 30px;
                margin-bottom: 0px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container {
                  height: 26px;
                  padding-top: 2px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__multi-value-item-container .vue-treeselect__multi-value-item {
                    line-height: 20px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip {
                  height: 26px;
                  padding-top: 2px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__limit-tip .vue-treeselect__limit-tip-text {
                    line-height: 20px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item .el-form-item__content .cus-treeselect .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value .vue-treeselect__input-container {
                  height: 20px;
                  line-height: 20px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item:first-of-type {
          width: calc(33% - 90px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item:nth-of-type(2) {
          width: calc(33% - 90px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item:nth-of-type(3) {
          width: calc(33% - 90px);
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item:nth-of-type(4) {
          width: 120px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8] .affect-compontent-item:last-of-type {
          width: 120px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .affect-compontent-form[data-v-8236feb8]:last-of-type {
        margin-bottom: 10px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .cus-treeselect[data-v-8236feb8] .vue-treeselect__control {
      height: 38px;
      line-height: 38px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .cus-treeselect[data-v-8236feb8] .vue-treeselect__control .vue-treeselect__multi-value {
        margin-bottom: 3px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .cus-treeselect[data-v-8236feb8] .vue-treeselect__control .vue-treeselect__single-value {
        line-height: 38px;
}
.trigger-form .affect-compontent-tabs .affect-compontent-tabpane .cus-treeselect[data-v-8236feb8] .vue-treeselect__control .vue-treeselect__placeholder {
        line-height: 30px;
}
