.factor-wrapper[data-v-314d7f8c] {
  margin: 0;
}
.factor-wrapper .factor-item[data-v-314d7f8c] {
    display: inline-block;
    padding: 4px;
    cursor: pointer;
}
.factor-wrapper .factor-item .factor-input[data-v-314d7f8c] {
      display: inline-block;
      border: 0px;
      width: 60px;
      outline: 0px;
      border: 1px solid #ccc;
}
.factor-wrapper .factor-item .factor-select[data-v-314d7f8c] {
      display: inline-block;
      border: 0px;
      width: 80px;
      outline: 0px;
      border: 1px solid #ccc;
}
.factor-wrapper .factor-item .factor-item-child[data-v-314d7f8c] {
      margin: 0px 4px;
}
.factor-wrapper .factor-item .factor-lost[data-v-314d7f8c] {
      border: 1px solid red;
      background: red;
      color: #fff;
}
.factor-wrapper .actived[data-v-314d7f8c] {
    border: 1px solid #ccc;
}
